<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <div>
        <CoreContainer>
            <BaseContainerContent
                :padding="{ top: 'none', bottom: 'large' }"
                class="flex !max-w-[100rem] flex-col items-center gap-10 lg:flex-row"
            >
                <div class="flex-2">
                    <div v-if="articlesResponse?.getItem()?.tags" class="flex flex-wrap gap-4">
                        <UiBadge
                            v-for="(tag, index) in articlesResponse?.getItem()?.tags"
                            :key="index"
                            color="simplo"
                            class="mb-2 w-fit"
                            content-class="font-bold uppercase"
                            pill
                        >
                            {{ tag }}
                        </UiBadge>
                    </div>

                    <h1 class="sim-h1 mb-5">
                        {{ articlesResponse?.getItem()?.name }}
                    </h1>

                    <p class="sim-article__text mb-5">
                        {{ articlesResponse?.getItem()?.perex }}
                    </p>

                    <BlogArticleCardDetails v-if="articlesResponse?.getItem()?.user"
                                            :author="articlesResponse?.getItem()?.user ?? null"
                                            :time="articlesResponse?.getItem()?.timeForReading"
                    />
                </div>

                <div class="flex-2 aspect-[7/4] w-full">
                    <CoreImg
                        :src="articlesResponse?.getItem()?.imageUrl"
                        :alt="articlesResponse?.getItem()?.name"
                        loading="lazy"
                        :width="712"
                        :height="406"
                        :placeholder="[89, 51, 100, 4]"
                        sizes="100vw sm:100vw md:100vw lg:50vw xl:50vw"
                        fit="contain"
                        class="size-full rounded-xl object-contain"
                    />
                </div>
            </BaseContainerContent>

            <BaseContainerContent
                :padding="{ top: 'none', bottom: 'large' }"
                width="narrow"
            >
                <div class="sim-wysiwyg-text" v-html="articlesResponse?.getItem()?.content" />

                <div class="mt-16">
                    <h2 v-if="articleProductsResponse?.getItems()?.length" class="sim-subtitle sim-font-bold mb-5">
                        {{ $t('labels.recommended_products') }}
                    </h2>

                    <SwiperCarousel v-if="articleProductsResponse?.getItems()?.length"
                                    :slides="articleProductsResponse.getItems().length ? articleProductsResponse.getItems() : Array.from({ length: 10 }, () => null)"
                    >
                        <template #slide="{ slide: product }">
                            <ProductCard
                                :product="product"
                                :loading="!articleProductsResponse?.getItems().length"
                            />
                        </template>
                    </SwiperCarousel>
                </div>
            </BaseContainerContent>

            <BaseContainerContent>
                <div class="sim-article__socials">
                    <span class="sim-eyebrow text-basic-700">
                        {{ $t('pages.blog.share') }}
                    </span>

                    <div class="flex gap-4">
                        <NuxtLink :to="shareOnFacebookUrl"
                                  target="_blank"
                                  :aria-label="$t('pages.blog.share_on_fb')"
                                  class="sim-menu-btn"
                        >
                            <IconFacebook class="fill-primary-400 p-2" aria-hidden="true" />
                        </NuxtLink>
                        <NuxtLink :to="shareOnTwitterUrl"
                                  target="_blank"
                                  :aria-label="$t('pages.blog.share_on_x')"
                                  class="sim-menu-btn"
                        >
                            <IconXSocial class="text-primary-700 p-2" aria-hidden="true" />
                        </NuxtLink>
                        <NuxtLink :to="shareOnLinkedInUrl"
                                  target="_blank"
                                  :aria-label="$t('pages.blog.share_on_in')"
                                  class="sim-menu-btn"
                        >
                            <IconLinkedin class="text-primary-700 p-2" aria-hidden="true" />
                        </NuxtLink>
                    </div>
                </div>

                <div class="sim-article-pagination">
                    <NuxtLink v-if="article?.articlePrev" :to="article.articlePrev.urls" class="sim-article-pagination__box sim-article-pagination__box--prev">
                        <CoreImg
                            :src="article.articlePrev.image_url"
                            :alt="article.articlePrev.name"
                            :height="290"
                            :sizes="article.articleNext ? '100vw sm:100vw md:50vw lg:50vw xl:50vw' : '100vw sm:100vw md:80vw lg:80vw xl:80vw'"
                            :placeholder="[60, 30, 100, 4]"
                            loading="lazy"
                            class="sim-article-pagination__box-image"
                        />

                        <span class="sim-eyebrow flex items-center gap-3">
                            <IconArrowRight size="sm" :rotate="180" />

                            {{ $t('pages.blog.prev_article') }}
                        </span>

                        <h5 class="sim-h5">
                            {{ article.articlePrev.name }}
                        </h5>
                    </NuxtLink>

                    <NuxtLink v-if="article?.articleNext" :to="article.articleNext.urls" class="sim-article-pagination__box sim-article-pagination__box--next">
                        <CoreImg
                            :src="article.articleNext.image_url"
                            :alt="article.articleNext.name"
                            :height="290"
                            :sizes="article.articlePrev ? '100vw sm:100vw md:50vw lg:50vw xl:50vw' : '100vw sm:100vw md:80vw lg:80vw xl:80vw'"
                            :placeholder="[60, 30, 100, 4]"
                            loading="lazy"
                            class="sim-article-pagination__box-image"
                        />

                        <span class="sim-eyebrow flex items-center gap-3">
                            {{ $t('pages.blog.next_article') }}

                            <IconArrowRight size="sm" />
                        </span>

                        <h5 class="sim-h5">
                            {{ article.articleNext.name }}
                        </h5>
                    </NuxtLink>
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <!--  NEWSLETTER SECTION  -->
        <SectionNewsletter />
    </div>
</template>

<script lang="ts" setup>
const { page } = useCurrentPage()

const [
    { data: articlesResponse, item: article },
] = await Promise.all([
    useArticlesApiService()
        .embed([
            ArticleModel.EMBED_USER,
            ArticleModel.EMBED_IMAGE_URL,
            ArticleModel.EMBED_DEFAULT_ARTICLE_CATEGORY,
            ArticleModel.EMBED_PRODUCT_IDS,
            ArticleModel.EMBED_ARTICLE_PREV,
            ArticleModel.EMBED_ARTICLE_NEXT,
            ArticleModel.EMBED_TAGS,
        ])
        .forId(page.value!.model!.id)
        .useGet(),
])

// TODO - lazy and client side
const [
    { data: articleProductsResponse },
] = await Promise.all([
    useProductsApiService()
        .embed([
            ProductModel.EMBED_PRODUCT_VARIATION_PROPERTIES,
            ProductModel.EMBED_COMPUTED_TAXED_PRICES,
            ProductModel.EMBED_DISCOUNT_PERCENTS,
            ProductModel.EMBED_DEFAULT_IMAGE_URL,
            ProductModel.EMBED_URLS,
            ProductModel.EMBED_PRODUCT_AVAILABILITY,
            ProductModel.EMBED_ACTIVE_PRODUCT_VARIATIONS_COUNT,
            ProductModel.EMBED_SECONDARY_IMAGE_URL,
        ])
        .whereIn(ProductModel.ATTR_ID, articlesResponse.value?.getItem()?.productIds ?? [])
        .useGet(),
])

const url = useRequestURL();

const shareOnTwitterUrl = computed(() => {
    const text = encodeURIComponent(articlesResponse.value?.getItem()?.name ?? '')

    return `https://twitter.com/intent/tweet?url=${url.href}&text=${text}`
})

const shareOnFacebookUrl = computed(() => {
    return `https://www.facebook.com/sharer.php?u=${url.href}`
})

const shareOnLinkedInUrl = computed(() => {
    const title = encodeURIComponent(articlesResponse.value?.getItem()?.name ?? '')
    const summary = encodeURIComponent(articlesResponse.value?.getItem()?.perex ?? '')

    return `https://www.linkedin.com/shareArticle?mini=true&url=${url.href}&title=${title}&summary=${summary}`
})

</script>

<style lang="scss" scoped>
.sim-article__text {
    @include sim-text-large;

    line-height: 200%;
}

.sim-article__socials {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;

    margin-bottom: 1rem;

    overflow: hidden;

    z-index: 1;

    @include more-than(sm) {
        flex-direction: row;
        gap: 2.5rem;
    }

    @include more-than(md) {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);

        padding: 1rem 1.75rem 1rem 3rem;
        margin-bottom: 0;
        width: max-content;

        background: white;
        border-radius: 5rem;
    }
}

.sim-article-pagination {
    display: flex;
    flex-direction: column;
    max-width: 100rem;
    gap: 1px;

    margin: auto;

    @include more-than(md) {
        flex-direction: row;
    }
}

.sim-article-pagination__box {
    flex: 1;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 0.25rem;

    padding: 2.1875rem 2.5rem;
    height: 13.75rem;

    border-radius: $sim-border-radius-sm;
    background: linear-gradient(rgb(255 255 255 / 0) 0%, $sim-c-primary-900 100%);
    color: white;

    overflow: hidden;

    &:last-child:not(:first-child) {
        border-radius: 0 0 $sim-border-radius-sm $sim-border-radius-sm;

        @include more-than(md) {
            border-radius: 0 $sim-border-radius-sm $sim-border-radius-sm 0;
        }
    }

    &:first-child:not(:last-child) {
        border-radius: $sim-border-radius-sm $sim-border-radius-sm 0 0;

        @include more-than(md) {
            border-radius: $sim-border-radius-sm 0 0 $sim-border-radius-sm;
        }
    }
}

.sim-article-pagination__box--next {
    align-items: end;
    text-align: right;
}

.sim-article-pagination__box-image {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    object-fit: cover;

    z-index: -1;

    transition: transform $sim-trans-time-long $sim-timing;
}

.sim-article-pagination__box:hover .sim-article-pagination__box-image {
    transform: scale(1.03);
}
</style>
